import { FlagAU, FlagCA, FlagNZ, FlagHK } from '@/public/assets/svg/flags';
import { Lang } from '@/types/locales';
import { ComponentType, SVGProps } from 'react';

export const LOCALE_FLAG_MAP: Record<Lang, ComponentType<SVGProps<SVGSVGElement>>> = {
  'en-ca': FlagCA,
  'en-au': FlagAU,
  'en-nz': FlagNZ,
  'fr-ca': FlagCA,
  'en-hk': FlagHK,
};
